import React from 'react';
import '../styles/header.css';
import mainLogo from '../images/main_logo.png';
import linkedin from '../images/linkedin.svg';
import br_flag from '../images/br_flag.svg';
import usa_flag from '../images/usa_flag.svg';
import { useTranslation } from 'react-i18next';
import '../utils/i18n';

import { handleLinkClick } from '../utils/functions';

export default function Header() {
  
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <section className='header-container'>
      <header className='header-principal'>
        <div id='main-logo'>
          <img src={mainLogo} alt='main_logo' />
        </div>
        <div id='header-fill'>
          <div id='header-fill-content'></div>
        </div>

        <ul className='header-list'>
          <li
            onClick={(e) => {
              e.preventDefault();
              handleLinkClick('about-title');
            }}
          >
            {t('about')}
          </li>
          <li
            onClick={(e) => {
              e.preventDefault();
              handleLinkClick('expertise-container');
            }}
          >
            {t('expertise')}
          </li>
          <li
            onClick={(e) => {
              e.preventDefault();
              handleLinkClick('group-container');
            }}
          >
            {t('team')}
          </li>
          <li
            onClick={(e) => {
              e.preventDefault();
              handleLinkClick('work-container');
            }}
          >
            {t('work')}
          </li>
          <li
            onClick={(e) => {
              e.preventDefault();
              handleLinkClick('contact-container');
            }}
          >
            {t('contact')}
          </li>
        </ul>
        <a id='linkedin-header' target='_blank' href='https://www.linkedin.com/company/sbgroup01' rel="noreferrer">
          <img src={linkedin} alt='linkedin_image' />
        </a>
        <div id='header-flags'>
          <img
            src={usa_flag}
            alt='usa_flag'
            onClick={() => changeLanguage('en')}
          />
          <img
            src={br_flag}
            alt='br_flag'
            onClick={() => changeLanguage('pt')}
          />
        </div>
      </header>
    </section>
  );
}
