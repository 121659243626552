import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    pt: {
      translation: {
        about: 'Sobre nós',
        expertise: 'Expertise',
        team: 'Equipe',
        work: 'Trabalhe Conosco',
        contact: 'Contato',
        aboutTitle: 'Sobre',
        aboutTitleSupport: 'nós',
        firstAboutText: ` é uma empresa dedicada a transformar possibilidades em realidade. Com essa nossa expertise e compromisso com a excelência, ajudamos as empresas do grupo a alcançarem seu máximo potencial. Nós acreditamos na força da colaboração e no poder das sinergias. Através da integração estratégica de nossos negócios, oferecemos soluções abrangentes e inovadoras que impulsionam o crescimento e o sucesso de nossos clientes.`,
        secondAboutText: `
    Nossa equipe talentosa e dedicada está focada em fornecer resultados excepcionais. Com profissionais altamente qualificados e apaixonados, buscamos constantemente aprimorar nossas capacidades e estar na vanguarda das últimas tendências e tecnologias.
        `,
        thirdAboutText: `
    Acreditamos também no valor da responsabilidade social corporativa. Nossas práticas de negócios são orientadas por princípios éticos e sustentáveis, buscando contribuir para o desenvolvimento econômico, social e ambiental  das comunidades onde atuamos. Se você está em busca de parceria com uma empresa que oferece soluções integradas e está comprometida com o sucesso de seus clientes, a SB Group é a escolha certa.
        `,
        missionTitle: 'Missão',
        missionContent: 'Transformar realidades conectando propósitos.',
        visionTitle: 'Visão',
        visionContent:
          'Ser um grupo comprometido \ncom a vontade de Deus, servindo as pessoas.',
        valuesTitle: 'Valores',
        valuesContent: 'Fé, servidão, justiça, inovação.',
        bGroup: 'Grupo',
        bBusiness: 'Empresarial',
        business: 'Empresas',
        desk: 'Escritório',
        countries: 'Paises',
        formTitle: 'Quero fazer parte da equipe',
        formTitleSupport: 'SB Group!',
        name: 'Nome',
        phone: 'Telefone',
        message: 'Mensagem',
        submit: 'Enviar',
        address: 'Endereço',
        companiesText: 'Grupo Empresarial com Crescimento Impulsionado',
        copyright: 'Todos os direitos reservados',
        terms: 'Termos de uso',
        privacy: 'Política de Privacidade',
        nextText: 'Proximo',
        prevText: 'Anterior',
        bussinesForGroup: 'Empresa do Grupo',
        firstBannerText: 'ESPECIALISTAS EM <br /><strong>INTELIGENCIA<br />ARTIFICIAL</strong><br />PARA SEGURANÇA.',
        firstBannerText2: '<strong>Utilizamos Inteligência Artificial</strong> <br />para segurança em cidades, <br />escolas, estádios e empresas.',
        secondBannerText: 'Savee Assessoria em Comério Internacional',
        secondBannerTextSupport:
          'possui soluções completas em importação e exportação',
        thirdBannerText: '<strong>Juntos</strong>, recuperamos <br/>metais preciosos cuidando <br/>do futuro',
        thirdBannerText2: 'RECICLAGEM DE LIXO ELETRÔNICO COM A <strong>SB RECYCLE<strong>.',
        partners: 'Parceiros',
        partnersSupport:
          '<strong>JUNTOS</strong> TRANSFORMAMOS REALIDADES<br/>CONECTANDO <strong>PROPÓSITOS</strong>.',
        ecoairBannerText: 'Oferece um avançado sistema de climatização para ônibus',
        ecoairBannerText2: 'o <strong>EcoAir Bus</strong>, que se destaca\n por sua total <strong>sustentabilidade</strong>.',

        planosNegociosTitle: 'Plano de negócios.',
        planosNegociosContent: 'Roteiro para o crescimento sustentável.',
        planejamentoEstrategicoTitle: 'Planejamento estratégico',
        planejamentoEstrategicoContent: 'Definindo o caminho para o futuro.',
        gestaoOrganizacionalTitle: 'Gestão Organizacional',
        gestaoOrganizacionalContent: 'Eficiência na estrutura corporativa.',
        gestaoPessoasTitle: 'Gestão de Pessoas',
        gestaoPessoasContent: 'Potencializando o capital humano.',
        financasEstrategicasTitle: 'Finanças estratégicas',
        financasEstrategicasContent: 'Gerenciando recursos com sabedoria.',
        marketingEstrategicoTitle: 'Marketing estratégico',
        marketingEstrategicoContent: 'Conquistando mercados com visão.',
        gestaoComercialTitle: 'Gestão comercial',
        gestaoComercialContent: 'Estratégias para o sucesso nas vendas.',
        treinamentoVendasTitle: 'Treinamento de vendas',
        treinamentoVendasContent: 'Desenvolvimento de equipes vencedoras.',
        businessInteligenceTitle: 'Business Intelligence',
        businessInteligenceContent: 'Dados transformados em decisões sólidas.',

        industry: 'Indústria',
        services: 'Serviços',
        agro: 'Agronegócio',
      },
    },
    en: {
      translation: {
        about: 'About Us',
        expertise: 'Expertise',
        team: 'Team',
        work: 'Work With Us',
        contact: 'Contact',
        aboutTitle: 'About',
        aboutTitleSupport: 'Us',
        firstAboutText: ` is a company dedicated to turning possibilities into reality. With our expertise and commitment to excellence, we help the group's companies reach their full potential. We believe in the strength of collaboration and the power of synergies. Through the strategic integration of our businesses, we deliver comprehensive and innovative solutions that drive our clients' growth and success.`,
        secondAboutText: `
          Our talented and dedicated team is focused on delivering exceptional results. With highly qualified and passionate professionals, we constantly seek to improve our capabilities and be at the forefront of the latest trends and technologies.
        `,
        thirdAboutText: `
          We also believe in the value of corporate social responsibility. Our business practices are guided by ethical and sustainable principles, seeking to contribute to the economic, social and environmental development of the communities where we operate. If you are looking to partner with a company that offers integrated solutions and is committed to the success of its customers, SB Group is the right choice.
        `,
        missionTitle: 'Mission',
        missionContent: 'Turn possibilities into reality.',
        visionTitle: 'Vision',
        visionContent: 'Drive business growth, transforming lives.',
        valuesTitle: 'Values',
        valuesContent: 'Faith, service, justice and innovation.',
        bGroup: 'Group',
        bBusiness: 'Business',
        business: 'Companies',
        teamWorld: 'Team',
        than: '+ than',
        members: 'members',
        desk: 'Desk',
        countries: 'Countries',
        formTitle: 'I want to be part of the team',
        formTitleSupport: 'SB Group!',
        name: 'Name',
        phone: 'Phone Number',
        message: 'Message',
        submit: 'Submit',
        address: 'Address',
        companiesText: 'Business Group with Driven Growth',
        copyright: 'All rights reserved',
        terms: 'Terms of use',
        privacy: 'Privacy Policy',
        nextText: 'Next',
        prevText: 'Previous',
        bussinesForGroup: 'Group Company',
        firstBannerText: 'SPECIALISTS IN',
        firstBannerTextSupport: 'ARTIFICIAL INTELLIGENCE',
        firstBannerTextSupportTwo: 'FOR SAFETY',
        secondBannerText: 'Savee International Trade Advisory',
        secondBannerTextSupport: 'has complete import and export solutions',
        thirdBannerText: 'We recover',
        thirdBannerTextSupport: 'metals',
        thirdBannerTextSupportTwo: 'taking care of the future.',
        partners: 'Partners',
        partnersSupport: 'together we transform\npossibilities into \nreality.',
        ecoairBannerText: 'Offers an advanced system',
        ecoairBannerText2: 'the EcoAir Bus, which stands out for its total',
        ecoairBannerText3: 'sustainability.',

        planosNegociosTitle: 'Business plan.',
        planosNegociosContent: 'Roadmap for sustainable growth.',
        planejamentoEstrategicoTitle: 'Strategic planning',
        planejamentoEstrategicoContent: 'Setting the path for the future.',
        gestaoOrganizacionalTitle: 'Organizational Management',
        gestaoOrganizacionalContent: 'Efficiency in the corporate structure.',
        gestaoPessoasTitle: 'People management',
        gestaoPessoasContent: 'Leveraging human capital.',
        financasEstrategicasTitle: 'Strategic Finance',
        financasEstrategicasContent: 'Managing Resources Wisely.',
        marketingEstrategicoTitle: 'Strategic marketing',
        marketingEstrategicoContent: 'Conquering markets with vision.',
        gestaoComercialTitle: 'Commercial management',
        gestaoComercialContent: 'Strategies for sales success.',
        treinamentoVendasTitle: 'Sales training',
        treinamentoVendasContent: 'Development of winning teams.',
        businessInteligenceTitle: 'Business Intelligence',
        businessInteligenceContent: 'Data transformed into solid decisions.',
        industry: 'Industry',
        services: 'Services',
        agro: 'Agribusiness',
      },
    },
  },
  lng: 'pt',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
