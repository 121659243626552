import React from 'react';
import '../styles/map.css';

export default function Map() {
  return (
    <div className='map-container'>
      <iframe
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3617.343437197702!2d-53.460024288171354!3d-24.954427314154312!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94f3d412f2ab724f%3A0xf3b2f59215cb1762!2sR.%20Paran%C3%A1%2C%202821%20-%20Centro%2C%20Cascavel%20-%20PR%2C%2085812-011!5e0!3m2!1spt-BR!2sbr!4v1692590106323!5m2!1spt-BR!2sbr'
        width='800'
        height='400'
        style={{ border: '0' }}
        allowFullScreen={true}
        loading='lazy'
        title='google_iframe'
        referrerPolicy='no-referrer-when-downgrade'
      ></iframe>
      <div className='map-mask'></div>
    </div>
  );
}
