import React from 'react';
import './styles/app.css';
import About from './components/About';
import Companies from './components/Companies';
import Contact from './components/Contact';
import Expertise from './components/Expertise';
import Footer from './components/Footer';
import Group from './components/Group';
import Header from './components/Header';
import Map from './components/Map';
import Missions from './components/Missions';
import Partners from './components/Partners';
import WorkWithUs from './components/WorkWithUs';

function App() {
  return (
    <div className='main-container'>
      <Header />
      <Partners />
      <About />
      <Missions />
      <Expertise />
      <Group />
      <Companies />
      <WorkWithUs />
      <Contact />
      <Map />
      <Footer />
    </div>
  );
}

export default App;
