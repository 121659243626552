import axios, { AxiosResponse } from 'axios';

export const scrollToElement = (elementId: string) => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};

export const handleLinkClick = (elementId: string) => {
  scrollToElement(elementId);
};

interface emailData {
  name: string;
  business?: string;
  phone: string;
  email: string;
  userMessage?: string;
}

export async function emailSendler(props: emailData) {
  const apiUrl =
    'https://2jxuf1qtsc.execute-api.sa-east-1.amazonaws.com/mailing';

  const data = {
    from: 'no-reply@sbgroup.com.br',
    to: 'hilton.junior@sbgroup.com.br',
    subject: `Contato via site - ${props.name}`,
    text: 'Contato via site.',
    template: 'sblabs',
    context: {
      sender_name: props.name,
      sender_business: props.business
        ? props.business
        : 'Empresa não fornecida',
      sender_email: props.email,
      sender_phone: props.phone,
      feedback_message: props.userMessage
        ? props.userMessage
        : 'Nenhuma mensagem digitada',
      header_image: 'https://i.imgur.com/9hrt7sJ.png',
      footer_image: 'https://i.imgur.com/HfIEjG3.png',
    },
  };

  const authToken =
    'c40434610c3ebc48f1ecec3794e0f12598ba7320674a8bf51c1d975f02996928';

  const headers = {
    Authorization: `Bearer ${authToken}`,
    'Content-Type': 'application/json',
  };

  try {
    const response: AxiosResponse<emailData[]> = await axios.post(
      apiUrl,
      data,
      {
        headers: headers,
      }
    );

    return response;
  } catch (error) {
    console.error('Erro ao buscar dados do endpoint:', error);
    throw error;
  }
}
