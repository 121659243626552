import React from 'react';
import '../styles/partners.css';
import {
  SecondBanner,
  ThirdBanner,
  FourthBanner,
  FirstBanner,
  FifthBanner,
} from '../utils/Banners';

//swiper importations
import 'swiper/css';
import { register } from 'swiper/element/bundle';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
register();

const divs = [
  {
    banner1: <FirstBanner />,
  },
  {
    banner2: <SecondBanner />,
  },
  // {
  //   banner3: <ThirdBanner />,
  // },
  {
    banner4: <FourthBanner />,
  },
  {
    banner4: <FifthBanner />,
  },
];

export default function Partners() {
  return (
    <>
      <div className='partners-container'>
        <div className='partners-image'>
          <Swiper
            // autoplay
            slidesPerView={1}
            pagination={{ clickable: false }}
            navigation
          >
            {divs.map((item, index) => (
              <SwiperSlide key={index}>{Object.values(item)[0]}</SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
}
