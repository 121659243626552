import { Trans, useTranslation } from "react-i18next";
import "../utils/i18n";
import saveeLogo from "../images/savee_logo2.svg";
import ecoairLogo from "../images/ecoair_logo.svg";
import firstBanner from "../images/banner4.svg";
import vector_sb from "../images/vector_sb.png";
import banner2 from "../images/banner.svg";
import banner3 from "../images/banner2.svg";
import banner4 from "../images/banner3.svg";
import banner5 from "../images/banner5.svg";

import "./banners.css";

const FirstBanner = () => {
  return (
    <div>
      <div className="first-banner-principal">
        {" "}
        <div className="first-banner-support">
          <img className="first-banner-image" src={vector_sb} alt="vector_sb" />
          <span className="first-banner-span">
            <Trans i18nKey={"partnersSupport"} />
          </span>
        </div>
      </div>
      <img src={firstBanner} className="main-banner" alt="first banner" />
    </div>
  );
};

const SecondBanner = () => {
  return (
    <div>
      {" "}
      <img src={banner2} className="main-banner" alt="second" />
      <div className="second-banner-principal">
        {/* <img className="security-logo" src={securityLogo} alt="logo security" /> */}
        <span className="second-banner-principal-span">
          <Trans i18nKey={"firstBannerText"} />
        </span>
        <span className="second-banner-principal-span-down">
          <Trans i18nKey={"firstBannerText2"} />
        </span>
      </div>
    </div>
  );
};

const ThirdBanner = () => {
  const { t } = useTranslation();
  return (
    <div>
      {" "}
      <div className="second-banner-div-business">
        <span className="second-banner-business-text">
          {t("bussinesForGroup")}
        </span>
        <span style={{ fontWeight: 500 }}>SB. Group</span>
      </div>
      <div className="third-banner-principal">
        <img className="third-banner-image" src={saveeLogo} alt="logo savee" />
        <span className="third-banner-principal-span">
          <b>{t("secondBannerText")}</b> <br></br>
          {t("secondBannerTextSupport")}
        </span>
      </div>
      <img src={banner3} className="main-banner" alt="third" />
    </div>
  );
};

const FourthBanner = () => {
  const { t } = useTranslation();
  return (
    <div>
      {" "}
      <div className="fourth-banner-principal">
        <span className="fourth-banner-principal-span">
          <Trans i18nKey={"thirdBannerText"} />
        </span>
        <div
          style={{
            display: "flex",
            gap: "20px",
            alignItems: "center",
          }}
        >
          <span className="fourth-banner-support-span">
            <Trans i18nKey={"thirdBannerText2"} />
          </span>
          <div className="div-business">
            <span className="second-banner-business-text">
              {t("bussinesForGroup")}
            </span>
            <span style={{ fontWeight: 500 }}>SB. Group</span>
          </div>
        </div>
      </div>
      <img src={banner4} className="main-banner" alt="fourth" />
    </div>
  );
};

const FifthBanner = () => {
  const { t } = useTranslation();
  return (
    <div>
      {" "}
      <div className="second-banner-div-business">
        <span className="second-banner-business-text">
          {t("bussinesForGroup")}
          <br />
          <span style={{ fontWeight: 500 }}>SB. Group</span>
        </span>
      </div>
      <div className="fifth-banner-principal">
        <img
          className="fifth-banner-image"
          src={ecoairLogo}
          alt="logo ecoair"
        />
        <div id="ecoair_banner_text_support">
          <span className="fifth-banner-principal-span">
            <span id="ecoairBannerText">
              <Trans i18nKey={"ecoairBannerText"} />
            </span>
          </span>
          <span id="ecoairBannerText1">
            <Trans i18nKey={"ecoairBannerText2"} />
          </span>
        </div>
      </div>
      <img src={banner5} className="main-banner" alt="fifth" />
    </div>
  );
};

export { FirstBanner, SecondBanner, ThirdBanner, FourthBanner, FifthBanner };
